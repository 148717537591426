<template>
  <div class="merchandise-info" style="height: auto">
    <!-- 標題 -->
    <div class="single-page-tag mb-3">
      <p class="sp-start sp-text fw-bolder">商品詳細資訊</p>
    </div>
    <div>
      <!-- 基本資訊 -->
      <div class="top-part">
        <!-- 上半部 -->
        <div class="row">
          <!-- 圖片 -->
          <div class="col-lg-2 col-sm-3 col-xs-12 p-0 pe-2">
            <Image
              :imageHash="merchandiseInfo.frontImageHash"
              v-if="merchandiseInfo.frontImageHash"
              :alt="merchandiseInfo.frontImageHash"
              style="width: 100%; min-width: 100px"
            ></Image>
            <img
              v-else
              src="@/assets/other-images/noImg.png"
              style="width: 100%; min-width: 100px"
            />
          </div>
          <!-- 按鈕們 -->
          <div class="col-lg-10 col-sm-9 col-xs-12 d-flex flex-wrap p-0">
            <p class="fw-bolder tw-text-size20 my-2">
              商品名稱: {{ merchandiseInfo.name }}
            </p>
            <div class="d-flex align-items-center mb-2 w-100">
              <input
                id="PlaceAnOrderLink"
                class="form-control me-3"
                type="text"
                :value="placeAnOrderLink"
                readonly
              />
              <button
                class="tw-btn tw-btn-success me-3"
                @click="copy('PlaceAnOrderLink')"
              >
                複製
              </button>
            </div>
            <div class="d-flex align-items-center">
              <button
                class="tw-btn tw-btn-success me-3"
                @click="showModal('addOrderBySeller')"
              >
                代客加單
              </button>
              <router-link
                :to="`/seller/store/${storeId}/editMerchandise?id=${merchandiseId}`"
                class="tw-btn tw-btn-success me-3"
                >前往編輯</router-link
              >
              <router-link
                :to="`/customer/store/${storeId}/orderPage?merchandiseId=${merchandiseId}&sellState=${merchandiseInfo.sellState}`"
                class="tw-btn tw-btn-success me-3"
                >前往購買</router-link
              >
              <router-link
                :to="`/seller/store/${storeId}/merchandise`"
                class="tw-btn tw-btn-secondary me-3"
                >返回商品管理</router-link
              >
            </div>
          </div>
        </div>
        <!-- 下半部換頁 nav -->
        <ul class="navigation">
          <li
            :class="{
              active: currentPage === 'Detail',
              'border-bottom-0': currentPage === 'Detail',
            }"
          >
            <router-link
              :to="`/seller/store/${storeId}/merchandiseInfo/detail?merchandiseId=${merchandiseId}`"
              >詳細資訊</router-link
            >
          </li>
          <li
            :class="{
              active: currentPage === 'Orders',
              'border-bottom-0': currentPage === 'Orders',
            }"
          >
            <router-link
              :to="`/seller/store/${storeId}/merchandiseInfo/orders?merchandiseId=${merchandiseId}`"
              >FB留言訂單</router-link
            >
          </li>
          <li
            :class="{
              active: currentPage === 'Stocks',
              'border-bottom-0': currentPage === 'Stocks',
            }"
          >
            <router-link
              :to="`/seller/store/${storeId}/merchandiseInfo/stocks?merchandiseId=${merchandiseId}`"
              >庫存紀錄</router-link
            >
          </li>
          <li
            :class="{
              active: currentPage === 'OrderGoods',
              'border-bottom-0': currentPage === 'OrderGoods',
            }"
          >
            <router-link
              :to="`/seller/store/${storeId}/merchandiseInfo/orderGoods?merchandiseId=${merchandiseId}`"
              >叫貨紀錄</router-link
            >
          </li>
        </ul>
        <!-- 分頁內容 -->
        <div
          class="tw-container p-0 merchandise-info-content m-0 mb-5 border-start border-end border-bottom border-2"
        >
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
  <AddOrderBySeller
    :participants="participants"
    :propsSelectInfos="propsSelectInfos"
    :showAddOrderBySeller="showAddOrderBySeller"
    @notifyParentFromAddOrderBySeller="notifyParentFromAddOrderBySeller"
  ></AddOrderBySeller>
</template>

<script>
// components
import AddOrderBySeller from '../../../components/modal/AddOrderBySeller.vue'

export default {
  components: {AddOrderBySeller},
  data() {
    return {
      serverToken: '',
      storeId: 0,
      merchandiseId: 0,
      currentPage: 'Detail',
      merchandiseInfo: {},
      participants: {
        loading: false,
        data: []
      },
      propsSelectInfos: {
        show: 'name',
        data: []
      },
      showAddOrderBySeller: false
    }
  },
  created() {
    this.initialization()
    this.getMerchandiseInfo()
    this.getAllParticipants()
  },
  watch: {
    $route(to) {
      this.currentPage = to.name
    },
  },
  computed: {
    placeAnOrderLink() {
      return `${process.env.VUE_APP_ORIGINURL}/customer/store/${this.storeId}/orderPage?merchandiseId=${this.merchandiseInfo.id}&sellState=${this.merchandiseInfo.sellState}`
    },
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
      this.merchandiseId = this.$route.query.merchandiseId
      this.currentPage = this.$route.name
    },
    showModal(status, item) {
      if (status === 'addOrderBySeller') {
        this.propsSelectInfos.data = []
        this.propsSelectInfos.data.push(this.merchandiseInfo)
        this.showAddOrderBySeller = true
      }
    },
    // 取得商品基本資訊
    getMerchandiseInfo() {
      const vm = this
      const getMerchandiseInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 16,
          ids: [this.merchandiseId],
          methods: '{getMerchandisePictureLinks,getMerchandiseStyles{getMerchandiseDiscounts}}'
        }
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getMerchandiseInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const merchandiseInfo = res.data[0].objects[0]
            // 商品圖片
            merchandiseInfo.frontImageHash = null
            merchandiseInfo.merchandisePictureLinks.some(item => {
              if (item.front) merchandiseInfo.frontImageHash = item.imageHash
            })
            vm.merchandiseInfo = merchandiseInfo
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    },
    // 取得賣場所有顧客
    getAllParticipants() {
      const vm = this
      const getStoreInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 5,
          ids: [this.storeId],
          methods: '{getParticipants{getUser}}'
        }
      ]
      this.participants = {
        loading: true,
        data: []
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoreInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            vm.participants = {
              loading: false,
              data: JSON.parse(JSON.stringify(storeInfo.participants))
            }
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 複製
    copy(elementId) {
      console.log(document.getElementById(elementId))
      document.getElementById(elementId).select();
      document.execCommand("Copy");
      this.SweetAlert('200')
    },
    // 來自<代客加單>子層的通知
    notifyParentFromAddOrderBySeller(object) {
      this.showAddOrderBySeller = object.showAddOrderBySeller
    },
  },
}
</script>